<template>
  <!--订单来源统计列表-->
  <div style="padding: 20px;">
    <div class="title">
      {{ $route.meta.title }}
    </div>

    <!--搜索条件-->
    <el-card shadow="never" style="margin-top: 20px;">
      <div class="card">
        <div class="card_t">
          <div class="card_t_icon"></div>
          <div class="card_t_text">
            筛选条件
          </div>
        </div>
        <div class="card_b">
          <!-- 搜索表单 -->
          <el-form
              :model="where"
              label-width="77px"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent>
            <el-row :gutter="15">
              <el-col :lg="8" :md="12">
                <el-form-item label="下单时间:">
                  <el-date-picker
                     v-model="where.time"
                     value-format="yyyy-MM-dd"
                     type="daterange"
                     unlink-panels
                     range-separator="至"
                     start-placeholder="开始日期"
                     end-placeholder="结束日期">
                  </el-date-picker>
                  <!-- <DatePicker v-model="where.time" :transfer="true" @on-change="datechange" format="yyyy-MM-dd" type="daterange" placement="bottom-end" placeholder="请选择"/> -->
                </el-form-item>
              </el-col>
              <el-col :lg="4" :md="12">
                <div class="ele-form-actions">
                  <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList">查询
                  </el-button>
                  <el-button @click="empty">清空</el-button>
                </div>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </el-card>

    <!--数据表格-->
    <el-card shadow="never">
      <div class="card">
        <div class="Datalist">
          <div class="Datalist_l">
            <div class="Datalist_icon"></div>
            <div class="Datalist_text">
              数据列表
            </div>
          </div>
        </div>

        <div class="card_b">
          <!-- 数据表格 -->
          <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :customsFromWhere="where"
              :loading="loading"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
          >
          </my-table>

        </div>
      </div>
    </el-card>

    <!--列表设置弹窗-->
    <div class="dialog">
      <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
// 权限
import {ShowTable} from '@/utils'
// 引入的接口
import {
  order_source_statistics,
} from '@/api/custom'

export default {
  // 组件生效
  components: {
    PackageSortElement
  },
  data(){
    return{
      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading:true,
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        // {
        //   label: "编号",
        //   prop: "id",
        //   isShow: true,
        //   fixed: 'left'
        // },
        // {
        //   label: "订单来源",
        //   prop: "source",
        //   isShow: true
        // },
        // {
        //   label: "订单来源",
        //   prop: "business_attribution",
        //   isShow: true
        // },
        // {
        //   label: "发包方",
        //   prop: "send_bag_name",
        //   isShow: true
        // },
        // {
        //   label: "订单数量",
        //   prop: "source_count",
        //   isShow: true
        // },
        // {
        //   label: "订单金额",
        //   prop: "price",
        //   isShow: true
        // },
        // {
        //   label: "合作状态",
        //   prop: "authentication_status",
        //   slot: "authentication_status",
        //   isShow: true
        // },
        {
          label: "客户名称",
          prop: "",
          isShow: true
        },
        {
          label: "订单总量",
          prop: "",
          isShow: true
        },
        {
          label: "总金额",
          prop: "",
          isShow: true
        },
        {
          label: "客单价",
          prop: "",
          isShow: true
        },
        {
          label: "取消量",
          prop: "",
          isShow: true
        },
        {
          label: "取消比%",
          prop: "",
          isShow: true
        },
        {
          label: "有效订单",
          prop: "",
          isShow: true
        },
        {
          label: "搭电数量",
          prop: "",
          isShow: true
        },
        {
          label: "搭电占比",
          prop: "",
          isShow: true
        },
        {
          label: "拖车数量",
          prop: "",
          isShow: true
        },
        {
          label: "拖车占比",
          prop: "",
          isShow: true
        },
        {
          label: "拖车平均公里数（拖车总公里数 / 拖车总订单数）",
          prop: "",
          isShow: true
        },
        {
          label: "困境数量",
          prop: "",
          isShow: true
        },
        {
          label: "困境占比",
          prop: "",
          isShow: true
        },
        {
          label: "地库数量",
          prop: "",
          isShow: true
        },
        {
          label: "地库占比",
          prop: "",
          isShow: true
        },
        {
          label: "充气数量",
          prop: "",
          isShow: true
        },
        {
          label: "充气占比",
          prop: "",
          isShow: true
        },
        {
          label: "换胎数量",
          prop: "",
          isShow: true
        },
        {
          label: "换胎占比",
          prop: "",
          isShow: true
        },
        {
          label: "送油数量",
          prop: "",
          isShow: true
        },
        {
          label: "送油占比",
          prop: "",
          isShow: true
        },
        {
          label: "抢修数量",
          prop: "",
          isShow: true
        },
        {
          label: "抢修占比",
          prop: "",
          isShow: true
        },
        {
          label: "吊车数量",
          prop: "",
          isShow: true
        },
        {
          label: "吊车占比",
          prop: "",
          isShow: true
        },



      ],

      // 列表设置弹窗
      ListSetupShow:false,

    }
  },

  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    //调用获取客户信息列表方法
    this.getList();
  },

  methods:{
    // 获取列表
    getList(){
      order_source_statistics(this.where).then(async (res) => {
        this.total = res.data.count;
        this.List = res.data.list;
        this.loading = false;
      }).catch((res) => {
        this.$Message.error(res.msg);
        this.loading = false;
      })
    },

    //选择时间
    datechange(e){
      this.where.time = e;
    },

    // 查询条件
    QueryList(){
      this.getList();
    },
    // 清空
    empty(){
      this.where = {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      }
      this.getList();
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log(selection)
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList()
    },

    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #F7F8FA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 跳转详情
    Todetails(){
      this.$router.push({
        path:'/custom/details.vue'
      })
    },

  }

}
</script>

<style lang="scss" scoped>

</style>
